import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { useLocalStorage } from "usehooks-ts";
import { add, formatISO } from "date-fns";

import { AuthProvider } from "../hooks/useAuth";
import { NotificationsProvider } from "../context/Notifications";

import { Notifications } from "./ui";
import Maintenance from "../pages/Maintenance/index";

import Router from "./Router";
import { defaultFetcherSwr } from "../helpers";
import { EMPTY_TOKEN } from "../global";

const App = () => {
  const [token, setToken] = useLocalStorage("token", EMPTY_TOKEN);

  const isMaintenanceMode = true; // Cambiar a true para activar el modo mantenimiento y false cuando termine el mantenimiento

  if (isMaintenanceMode) {
    return <Maintenance isMaintenanceMode={isMaintenanceMode} />;
  }

  return (
    <BrowserRouter>
      <NotificationsProvider>
        <Notifications />
        <SWRConfig
          value={{
            fetcher: defaultFetcherSwr,
            onSuccess: () => {
              if (token.value) {
                setToken({
                  ...token,
                  expirationDate: formatISO(add(new Date(), { hours: 1 })),
                });
              }
            },
          }}
        >
          <AuthProvider>
            <Router />
          </AuthProvider>
        </SWRConfig>
      </NotificationsProvider>
    </BrowserRouter>
  );
};

export default App;
